//@import "../css/animate.min.css";
//@import "../css/bootstrap.min.css";
//@import "../css/boxicons.min.css";
//@import "../css/magnific-popup.css";
//@import "../css/meanmenu.css";
//@import "../css/nice-select.min.css";
//@import "../css/owl.carousel.min.css";
//@import "../css/owl.theme.default.min.css";
//@import "../css/responsive.css";
//@import "../css/style.css";
//@import "../css/theme-dark.css";

.brand-slider{
  .owl-stage{
    display: flex !important;
    align-items: center !important;
  }
}
.brand-item{
  a{
    img{
      height: auto !important;
    }
  }
}
.page-numbers{
  margin: 0 8px 0;
  color: #0152a3;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px #d0d0d0;
  box-shadow: 0 0 15px #d0d0d0;
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  line-height: 24px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &.current{
    z-index: 1;
    color: #ffffff;
    background-color: #0152a3;
    border-color: #0152a3;
  }
  &.prev{
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  &.next{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
.top-header{
  position: relative;
  z-index: 2;
}
h1, h2{
  &.section-title{
    margin-bottom: 1.5rem;
    span{
      font-size: 1.5rem;
    }
  }
}
.sidebar-modal{
  .header-right{
    .header-right-card{
      ul{
        li{
          &:before{
            display: none !important;
          }
        }
      }
    }
  }
}
.header-right{
  .header-right-card{
    ul{
      li{
        &:before{
          top: 20px !important;
        }
        a{
          @media only screen and (max-width: 1439px){
            font-size: 13px !important;
          }
        }
      }
    }
  }
}
.main-nav{
  .menu-item{
    position: relative;
    padding: 15px 0;

    &.icon-home {
      a {
        text-indent: -1000em;
        &:before {
          font-family:boxicons;
          content:"\eb12";
        }
      }
    }
    a{
      font-size: 16px;
      padding: 0;
      text-transform: uppercase;
      color: #fff;
      font-weight: 500;
      margin-left: 12px;
      margin-right: 12px;
      &:hover{
        color: #e52d27 !important;
      }
      @media only screen and (min-width: 992px) and (max-width: 1279px){
        font-size: 12px;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
    &.current-menu-item, &.current-page-ancestor, &.current-page-parent{
      a{
        color: #e52d27 !important;
      }
    }
  }
}
.virtual-btn{
  color: #1f1f1f;
  border-color: #0152a3;
  border-radius: 50px;
  &:hover{
    color: #fff;
    background-color: #0152a3;
    border-color: #0152a3;
  }
}
.service-catagory{
  ul{
    li{
      &:not(.active){
        padding: 0 !important;
        a{
          padding: 16px 16px !important;
        }
      }
    }
  }
}
.woocommerce .button{
  border-radius: 0 !important;
  background-color: #0152a3 !important;
  color: #fff !important;
  text-transform: uppercase !important;
  padding: 12px 27px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.8 !important;
}
.woocommerce a.remove{
  color: #0152a3 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 1 !important;
  font-size: 21px !important;
}
.woocommerce a.remove:hover{
  color: #fff !important;
  background: #0152a3 !important;
}
.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea, .woocommerce form .form-row select, .woocommerce form .form-row .select2-selection__rendered{
  padding: 12px !important;
  font-size: 16px !important;
  border-radius: 0 !important;
  border-width: 1px !important;
  line-height: 1 !important;
}
.woocommerce .select2-selection{
  border: 1px solid #767676 !important;
  height: auto !important;
  border-radius: 0 !important;
}
.woocommerce .select2-selection__arrow{
  height: 100% !important;
}
.woocommerce select{
  float: none !important;
  border-radius: 0 !important;
  width: 100% !important;
}
.woocommerce table.shop_table{
  border-radius: 0 !important;
}
.woocommerce form .form-row label{
  color: #1f1f1f !important
}
#add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment{
  border-radius: 0 !important;
}
#add_payment_method #payment div.payment_box, .woocommerce-cart #payment div.payment_box, .woocommerce-checkout #payment div.payment_box{
  border-radius: 0 !important;
}
#order_review_heading{
  margin-top: 24px !important;
}
.woocommerce ul.order_details{
  padding-left: 0 !important;
}
@media only screen and (max-width: 767px){
  .service-catagory{
    ul{
      li{
        &:not(.active) {
          padding: 0 !important;
          a {
            padding: 16px 7px !important;
          }
        }
      }
    }
  }

  .header-right {
    .header-right-card {
      ul {
        li {
          margin-right: 0;
        }
      }
    }
  }
}